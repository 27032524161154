import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Card, Icon, Popup, Grid, Label } from 'semantic-ui-react'
import Photo from '../Photo'
import { getErrorMessage } from './../../util/errors'
import { isPermitted } from "../../util/acl"
import { Link } from 'react-router-dom'
import { LOBSTER_POT_4 } from '../../config'

/**
 * Class representing a card displaying a single camera
 *
 * Used in the camera grid
 */

class Camera extends Component {

    constructor(props) {
        super(props)
        this.state = { info: false }
    }

    getCameraInfoLink = (item) => {
        if (item.type === 'Virtual' || item.type === 'Video') {
            return null
        }

        let cameraInfoLink = null
        if (isPermitted(this.props.auth.user, 'post-cameras-getinfo')) {

            const activeClassname = (this.state.info) ? 'active' : ''
            cameraInfoLink = (
                <Popup
                    offset={[-5, 5]}
                    trigger={<Icon circular={true} className={`camera-info-link ${activeClassname}`} link={true} name='info' onClick={() => {
                        this.setState((prevState => {
                            const info = !prevState.info
                            return { info: info }
                        }), () => {
                            if (this.state.info === true) {
                                this.props.onFetchCameraInfo(this.props.camera.id)
                            }
                        })
                    }}/>}
                    content='Camera Info'
                />
            )
        }
        return cameraInfoLink
    }

    getCameraControlLink = (item) => {
        if (item.type === 'Virtual' || item.type === 'Windows' || item.type === 'Video') {
            return null
        }

        let cameraControlLink = null
        if (isPermitted(this.props.auth.user, 'get-cameracontrol-status')) {
            if (item.pot_version === LOBSTER_POT_4) {
                cameraControlLink = (
                    <Popup
                        offset={[-13, 0]}
                        trigger={
                            <a
                                target='_blank'
                                className='camera-control-link'
                                href={`/cameras/${item.id}/camera-control-v4`}
                                rel='noopener noreferrer'

                                >
                                <Icon className="iris" />
                            </a>
                        }
                        content='Camera Control V4'
                    />
                )
            }
            else {
                cameraControlLink = (
                    <Popup
                        offset={[-13, 0]}
                        trigger={
                            <a
                                target='_blank'
                                className='camera-control-link'
                                href={`/cameras/${item.id}/camera-control`}
                                rel='noopener noreferrer'
                                >
                                <Icon className="iris" />
                            </a>
                        }
                        content='Camera Control'
                    />
                )
            }

        }
        return cameraControlLink
    }

    getScheduleLink = (item) => {
        if (item.type === 'Virtual'|| item.type === 'Windows' || item.type === 'Video') {
            return null
        }
        let scheduleLink = null
        if (isPermitted(this.props.auth.user, 'get-scheduler-schedule')) {
            scheduleLink = (
                <Popup
                    offset={[-13, 0]}
                    trigger={
                        <Link
                            className='camera-scheduler-link'
                            to={`/cameras/${item.id}/schedule`}
                        >
                            <Icon name='calendar' link />
                        </Link>
                    }
                    content='Schedule'
                />
            )
        }
        return scheduleLink
    }

    getUpdatedCameraLink = (item) => {
        let updateCameraLink = null
        if (isPermitted(this.props.auth.user, 'put-cameras-cameras')) {
            updateCameraLink = (

                <Popup
                    offset={[-13, 0]}
                    trigger={<Icon className='camera-modal-link camera-settings-link' link={true} name='cogs' onClick={() => {
                        this.props.onOpenModal(item.id)
                    }}/>}
                    content='Edit'
                />

            )
        } else {
            updateCameraLink = (<b>No</b>)
        }
        return updateCameraLink
    }

    getMonitoringLink = (item) => {
        if (item.type === 'Virtual') {
            return null
        }

        let monitoringLink = null

        if (isPermitted(this.props.auth.user, 'MONITORING_CAMERAS_LINK')) {
            if (item.pot_version === LOBSTER_POT_4) {
                monitoringLink = (
                    <Popup
                        offset={[-13, 0]}
                        trigger={
                            <a
                                target='_blank'
                                className='camera-monitoring-link'
                                href={`https://monitoring.int.lobsterpictures.tv/graphite/?showTarget=lobster.pots.${item.pot_id}`}
                                rel='noopener noreferrer'
                                >
                                <Icon name='bar chart' link />
                            </a>
                        }
                        content='Monitoring'
                    />
                )
            }
            else {
                monitoringLink = (
                    <Popup
                        offset={[-13, 0]}
                        trigger={
                            <a
                                target='_blank'
                                className='camera-monitoring-link'
                                href={`https://monitoring.vpn.lobsterpictures.tv/monitoring/check_mk/index.py?start_url=%2Fmonitoring%2Fcheck_mk%2Fview.py%3Fhost%3Dlob-pot-${item.pot_id}%26site%3Dmonitoring%26view_name%3Dhost`}
                                rel='noopener noreferrer'
                                >
                                <Icon name='bar chart' link />
                            </a>
                        }
                        content='Monitoring'
                    />
                )
            }
        }
        return monitoringLink
    }

    getGrayLogsLink = (item) => {
        if (item.pot_version !== LOBSTER_POT_4 || item.type === 'Virtual' || item.type === 'Video') {
            return null
        }

        let grayLogsLink = null

        if (isPermitted(this.props.auth.user, 'MONITORING_CAMERAS_LINK')) {
            if (item.pot_version === LOBSTER_POT_4) {
                grayLogsLink = (
                    <Popup
                        offset={[-13, 0]}
                        trigger={
                            <a
                                target='_blank'
                                className='camera-graylog-link'
                                href={`https://monitoring-new.vpn.lobsterpictures.tv/logs/search?q=source%3A+%22pcid-${item.pcid}%22&rangetype=relative&from=3600`}
                                rel='noopener noreferrer'
                                >
                                <Icon name='file alternate outline' link />
                            </a>
                        }
                        content='GrayLogs'
                    />
                )
            }
        }
        return grayLogsLink
    }

    getAuthAsCameraLink = (item) => {
        let authAsCameraLink = null
        if (isPermitted(this.props.auth.user, 'get-authas-camera')) {
            authAsCameraLink = (
                <Popup
                    offset={[-13, 0]}
                    trigger={<Icon link={true} name='computer' onClick={() => {
                        this.props.authAsCamera(item.id)
                    }}/>}
                    content='Auth as camera'
                />
            )
        }
        return authAsCameraLink
    }

    getFileListLink = (item) => {
        if (item.type === 'Windows') {
            return null
        }
        let fileListLink = null
        if (isPermitted(this.props.auth.user, 'get-images-imagelist')) {
            fileListLink = (
                <Popup
                    offset={[-13, 0]}
                    trigger={
                        <Link
                            className='camera-filelist-link'
                            to={`/cameras/${item.id}/file-list`}
                        >
                            <Icon name='folder outline' link />
                        </Link>
                    }
                    content='File list'
                />
            )
        }
        return fileListLink
    }

    getCameraUploadLink = (item) => {
        let cameraUploadLink = null
        if (isPermitted(this.props.auth.user, 'get-cameras-upload')) {
            cameraUploadLink = (
                <Popup
                    offset={[-13, 0]}
                    trigger={
                        <Link
                            className='camera-upload-link'
                            to={`/cameras/${item.id}/upload`}
                        >
                            <Icon name='upload' link />
                        </Link>
                    }
                    content='Upload Images'
                />
            )
        }
        return cameraUploadLink
    }

    getCameraTags = () => {
        const cameraTags = this.props.camera.tags
        if (!cameraTags) {
            return null
        }
        const tags = cameraTags.map((item, idx) => {
           return (
               <Popup
                   key={idx}
                   offset={[-13, 0]}
                   trigger={<Label circular={true} empty={true} color={item.color} className={`tags active`} />}
                   content={`${item.name} Tag`}
               />

           )
        })
        return (<div className='tags-dot-container'>{tags}</div>)
    }

    getSetupModeInfo = () => {
        if (this.props.camera.pot_version !== LOBSTER_POT_4) {
            return null
        }

        let cameraSetupMode = null

        switch(this.props.camera.setup_mode_state) {
            case 'On':
                cameraSetupMode = {color: 'green', name:'On'}
                break;
            case 'OffRequested':
                cameraSetupMode = {color: 'orange', name:'Off R'}
                break;
            case 'OnRequested':
                cameraSetupMode = {color: 'yellow', name:'On R'}
                break;
            default:
                cameraSetupMode = null
        }

        if (cameraSetupMode === null) {
            return null
        }

        return (
            <div className='setup-mode-dot-container'>
            <Label color={cameraSetupMode.color}>{cameraSetupMode.name}</Label>
            </div>
        )
    }

    toTitleCase = (str) => {

        str = str.replace(
            /_/g,
            text => ' '
        );

        return str.replace(
          /\w\S*/g,
          text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
        );
      }

    getSuppressedWarningsInfo = () => {
        if (this.props.camera.pot_version !== LOBSTER_POT_4) {
            return null
        }

        if (this.props.camera.vc_parameters == null) {
            return null
        }

        const warnings = JSON.parse(this.props.camera.vc_parameters);

        if (warnings.supress_grid_warnings === undefined) {
            return null
        }

        let popupContent = ''
        warnings.supress_grid_warnings.map((warning) =>  popupContent += ' ' + this.toTitleCase(warning) + ', ')
        popupContent = popupContent.substring(0, popupContent.length - 2);

        let suppressedWarningsPopup = (
            <Popup
                offset={[-13, 0]}
                trigger={
                      <Icon className="red" name='exclamation'/>
                }
                header='Suppressed Warnings'
                content={popupContent}
            />
        )

        return suppressedWarningsPopup
    }

    getPreloader = () => {
        return (
            <div className='camera-info-overlay'>
                <div className='centered-container'>
                    <p className='centered'>Loading...</p>
                </div>
            </div>
        )
    }

    getStatusColor = () => {
        if (this.props.vanity || this.props.camera.type === 'Virtual') {
            return null
        }
        if (!this.props.camera.monitoring) {
            return 'black'
        }
        switch (this.props.camera.monitoring.errorLevel) {
            case 0:
                return null
            case 1:
                return 'yellow'
            case 2:
                return 'red'
            default:
                return null
        }
    }

    getLabel = () => {
        if (this.props.vanity || this.props.camera.type === 'Virtual') {
            return null
        }
        if (!this.props.camera.monitoring) {
            return {
                content: 'No response from monitoring',
                color: 'black',
                ribbon: true
            }
        }
        if (this.props.camera.monitoring.errorLevel === 0) {
            return null
        } else {
            return {
                content: this.props.camera.monitoring.message,
                color: this.getStatusColor(),
                ribbon: true
            }
        }
    }

    render() {

        // If it's the vanity grid, hide any non-functioning cameras
        if (this.props.vanity && this.props.camera.monitoring) {
            if (this.props.camera.monitoring.errorLevel > 0) {
                return null
            }
        }

        if (this.props.antivanity && this.props.camera.monitoring) {
            if (this.props.camera.monitoring.errorLevel < 1 || this.props.camera.monitoring.level < 1) {
                return null
            }
        }

        const cameraTags = this.getCameraTags()
        const getSetupModeInfo = this.getSetupModeInfo()
        const suppressedWarningInfo = this.getSuppressedWarningsInfo(this.props.camera)

        let cameraControlLink = this.getCameraControlLink(this.props.camera)
        let updateCameraLink = this.getUpdatedCameraLink(this.props.camera)
        let scheduleLink = this.getScheduleLink(this.props.camera)
        let monitoringLink = this.getMonitoringLink(this.props.camera)
        let grayLogsLink = this.getGrayLogsLink(this.props.camera)
        let cameraInfoLink = this.getCameraInfoLink(this.props.camera)
        let authAsCameraLink = this.getAuthAsCameraLink(this.props.camera)
        let fileListLink = this.getFileListLink(this.props.camera)
        let cameraUploadLink = this.getCameraUploadLink(this.props.camera)
        let info = null
        if (this.state.info) {

            // Okay, so we need to determine if loading, errors, success...
            if (!this.props.info) {
                info = this.getPreloader()
            } else if (this.props.info && this.props.info.loading) {
                info = this.getPreloader()
            } else if (this.props.info && this.props.info.error) {
                info = (
                    <div className='camera-info-overlay'>
                        <div className='centered-container'>
                            <div className='erred'>
                                <h3 className='error'>Error</h3>
                                <p className='error'>{getErrorMessage(this.props.info.errors, 'cameras_id')}</p>
                                <p className='error'>{getErrorMessage(this.props.info.errors, 'unknownErrorType')}</p>
                            </div>
                        </div>
                    </div>
                )
            } else if (this.props.info && this.props.info.success) {
                if (this.props.camera.pot_version === LOBSTER_POT_4) {
                    let cameraState, platform, timezone, upTime, modemSignalStrength, wifiState, wifiSignalStrength, ambientTemperature, ambientHumidity,
                        chargerFault, voltageInternal, voltageExternal, voltageDcIn, currentSystem, operatorCode, fileQueueSize, applicationVersion,
                        systemVersion, diagnosticsVersion, accessTech, chargerStatusDecription, chargerLowPowerMode;

                    if (this.props.info.details.state !== undefined) {
                        cameraState = this.props.info.details.state
                    }
                    if (this.props.info.details.monitoring_data.system !== undefined &&
                        this.props.info.details.monitoring_data.system.platform !== undefined) {
                        platform = this.props.info.details.monitoring_data.system.platform
                    }
                    if (this.props.info.details.monitoring_data.system !== undefined &&
                        this.props.info.details.monitoring_data.system.timezone !== undefined) {
                        timezone = this.props.info.details.monitoring_data.system.timezone
                    }
                    if (this.props.info.details.monitoring_data.system !== undefined &&
                        this.props.info.details.monitoring_data.system.uptime !== undefined) {
                        upTime = this.props.info.details.monitoring_data.system.uptime
                    }
                    if (this.props.info.details.monitoring_data.modem !== undefined) {
                        modemSignalStrength = this.props.info.details.monitoring_data.modem.signal_strength
                    }
                    if (this.props.info.details.monitoring_data.modem !== undefined) {
                        operatorCode = this.props.info.details.monitoring_data.modem.operator_code_string
                    }
                    if (this.props.info.details.monitoring_data.modem !== undefined) {
                        accessTech = this.props.info.details.monitoring_data.modem.access_tech
                    }
                    if (this.props.info.details.monitoring_data.wifi !== undefined &&
                        this.props.info.details.monitoring_data.wifi.signal_strength !== '??') {
                        wifiState = this.props.info.details.monitoring_data.wifi.state
                        wifiSignalStrength = this.props.info.details.monitoring_data.wifi.signal_strength
                    }
                    if (this.props.info.details.monitoring_data.sensors.temperature !== undefined) {
                        ambientTemperature = this.props.info.details.monitoring_data.sensors.temperature.bme280
                    }
                    if (this.props.info.details.monitoring_data.sensors.humidity !== undefined) {
                        ambientHumidity = this.props.info.details.monitoring_data.sensors.humidity.bme280
                    }
                    if (this.props.info.details.monitoring_data.charger !== undefined) {
                        if (this.props.info.details.monitoring_data.charger.fault_description !== undefined) {
                            chargerFault = this.props.info.details.monitoring_data.charger.fault_description
                        }

                        if (this.props.info.details.monitoring_data.charger.status_description !== undefined) {
                            chargerStatusDecription = this.props.info.details.monitoring_data.charger.status_description
                        }

                        if (this.props.info.details.monitoring_data.charger.low_power_mode !== undefined) {
                            chargerLowPowerMode = this.props.info.details.monitoring_data.charger.low_power_mode ? 'true' : 'false'
                        }
                    }
                    if (this.props.info.details.monitoring_data.sensors.voltage !== undefined) {
                        voltageInternal = this.props.info.details.monitoring_data.sensors.voltage.internal_battery > 0 ?
                            this.props.info.details.monitoring_data.sensors.voltage.internal_battery : '0.0'
                        voltageExternal = this.props.info.details.monitoring_data.sensors.voltage.external_battery > 0 ?
                            this.props.info.details.monitoring_data.sensors.voltage.external_battery : null
                        voltageDcIn = this.props.info.details.monitoring_data.sensors.voltage.dc_in > 0 ?
                            this.props.info.details.monitoring_data.sensors.voltage.dc_in : null
                    }
                    if (this.props.info.details.monitoring_data.sensors.current !== undefined) {
                        currentSystem = (this.props.info.details.monitoring_data.sensors.current.system > 0) ?
                            this.props.info.details.monitoring_data.sensors.current.system * 1000 : null
                    }
                    if (this.props.info.details.monitoring_data.camera_control !== undefined) {
                        fileQueueSize = this.props.info.details.monitoring_data.camera_control.file_queue_size > 0 ?
                            this.props.info.details.monitoring_data.camera_control.file_queue_size : null
                    }
                    if (this.props.info.details.monitoring_data.system !== undefined &&
                        this.props.info.details.monitoring_data.system.package_versions !== undefined) {
                        applicationVersion = this.props.info.details.monitoring_data.system.package_versions.lobster_pot_four_application !== undefined ?
                            this.props.info.details.monitoring_data.system.package_versions.lobster_pot_four_application : null

                        systemVersion = this.props.info.details.monitoring_data.system.package_versions.lobster_pot_four_system !== undefined ?
                            this.props.info.details.monitoring_data.system.package_versions.lobster_pot_four_system : null

                        diagnosticsVersion = this.props.info.details.monitoring_data.system.package_versions.lobster_pot_four_diagnostics !== undefined ?
                            this.props.info.details.monitoring_data.system.package_versions.lobster_pot_four_diagnostics : null
                    }
                    info = (
                        <div className='camera-info-overlay'>
                            <div>
                                <Grid columns='1'>
                                    <Grid.Row>
                                        <Grid.Column>
                                            { cameraState && <p><span className='key'>State:</span> <span className='value'>{cameraState}</span></p> }
                                            { platform && <p><span className='key'>Platform:</span> <span className='value'>{platform}</span></p> }
                                            { timezone && <p><span className='key'>Time Zone:</span> <span className='value'>{timezone}</span></p> }
                                            { fileQueueSize && <p><span className='key'>Queue Size:</span> <span className='value'>{fileQueueSize}</span></p> }
                                            { upTime && <p><span className='key'>Uptime:</span> <span className='value'>{upTime}</span></p> }
                                            { modemSignalStrength && <p><span className='key'>Modem Signal Strength:</span> <span className='value'>{modemSignalStrength} %</span></p> }
                                            { operatorCode && <p><span className='key'>Operator:</span> <span className='value'>{operatorCode}</span></p> }
                                            { accessTech && <p><span className='key'>Access Tech:</span> <span className='value'>{accessTech}</span></p> }
                                            { wifiState && <p><span className='key'>Wifi:</span> <span className='value'>{wifiState}</span></p> }
                                            { wifiSignalStrength && <p><span className='key'>Wifi Signal Strength:</span> <span className='value'>{wifiSignalStrength} %</span></p> }
                                            { ambientTemperature && <p><span className='key'>Temperature:</span> <span className='value'>{ambientTemperature} ℃</span></p> }
                                            { ambientHumidity && <p><span className='key'>Humidity:</span> <span className='value'>{ambientHumidity} %</span></p> }
                                            { chargerFault && <p><span className='key'>Charger Fault:</span> <span className='value'>{chargerFault}</span></p> }
                                            { chargerStatusDecription && <p><span className='key'>Charger Status:</span> <span className='value'>{chargerStatusDecription}</span></p> }
                                            { chargerLowPowerMode && <p><span className='key'>Charger Low Power Mode:</span> <span className='value'>{chargerLowPowerMode}</span></p> }
                                            { voltageInternal && <p><span className='key'>Internal Battery Voltage:</span> <span className='value'>{voltageInternal} V</span></p> }
                                            { voltageExternal && <p><span className='key'>External Battery Voltage:</span> <span className='value'>{voltageExternal} V</span></p> }
                                            { voltageDcIn && <p><span className='key'>DC In Voltage:</span> <span className='value'>{voltageDcIn} V</span></p> }
                                            { currentSystem && <p><span className='key'>System Current:</span> <span className='value'>{currentSystem} mA</span></p> }
                                            { applicationVersion && <p><span className='key'>Application Version:</span> <span className='value'>{applicationVersion}</span></p> }
                                            { systemVersion && <p><span className='key'>System Version:</span> <span className='value'>{systemVersion}</span></p> }
                                            { diagnosticsVersion && <p><span className='key'>Diagnostics Version:</span> <span className='value'>{diagnosticsVersion}</span></p> }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </div>
                    )
                }
                else {
                    info = (
                        <div className='camera-info-overlay'>
                            <div>
                                <Grid columns='1'>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <p><span className='key'>Model:</span> <span className='value'>{this.props.info.details.model}</span></p>
                                            <p><span className='key'>IP:</span> <span className='value'>{this.props.info.details.extip}</span></p>
                                            <p><span className='key'>Timezone:</span> <span className='value'>{this.props.info.details.timezone}</span></p>
                                            <p><span className='key'>Uptime:</span> <span className='value'>{this.props.info.details.uptime}</span></p>
                                            <p><span className='key'>Recent Shot:</span> <span className='value'>{this.props.info.details.recent_shot}</span></p>
                                            <p><span className='key'>Volts:</span> <span className='value'>{this.props.info.details.volts}</span></p>
                                            <p><span className='key'>Humidity:</span> <span className='value'>{this.props.info.details.humidity}</span></p>
                                            <p><span className='key'>Temperature:</span> <span className='value'>{this.props.info.details.temperature}</span></p>
                                            <p><span className='key'>Storage:</span> <span className='value'>{this.props.info.details.storage}</span></p>
                                            <p><span className='key'>FS:</span> <span className='value'>{this.props.info.details.fs}</span></p>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </div>
                    )
                }
            }
        }

        // Format card header
        let header = this.props.camera.pot_id
        if (!this.props.hideClients && this.props.camera.clients_name) {
            header += `: ${this.props.camera.clients_name}`
            if (this.props.camera.projects_name) {
                header += `, ${this.props.camera.projects_name}`
            }
        }

        // Format latest image time
        let latestImage = null
        if (this.props.camera.latest_datetime) {
            latestImage = this.props.camera.latest_datetime
            // Remove seconds if :00
            if (latestImage.substring(5,8) === ':00') {
                latestImage = latestImage.substr(0, 5) + latestImage.substr(8);
            }
        }

        // Display any custom camera name
        let customName = null
        if (this.props.camera.custom_name) {
            customName = (
                <Card.Description className="description" style={{
                    fontSize: '1.1em',
                }}>
                    {this.props.camera.custom_name}
                </Card.Description>
            )
        }

        let pcid = null
        if (this.props.camera.pcid) {
            pcid = (
                <small>
                    <br />PCID: {this.props.camera.pcid}
                </small>
            )
        }

        // Display last check in time
        let lastCheckInTime = null
        if (this.props.camera.last_check_in) {
            lastCheckInTime = (
                <small>
                    <br />Checkin: {this.props.camera.last_check_in}
                </small>
            )

        }

        let location = 'Unknown'
        if (this.props.camera.projects_location) {
            location = (
                <small>
                    Location: {this.props.camera.projects_location}
                </small>
            )
        }

        let subscriptionStatus = null
        if (this.props.camera.subscription_status) {
            let colour
            if (this.props.camera.subscription_status != 'subscribed') {
                colour = 'red'
            }
            subscriptionStatus = (
                <Card.Description className="description" style={{
                    fontSize: '1.1em'
                }}>
                    <Label color={colour} style={{textTransform: 'capitalize'}} size="mini">{this.props.camera.subscription_status}</Label>
                </Card.Description>
            )

        }

        const statusColor = this.getStatusColor()

        let portraitTagIndex = null

        if (this.props.camera && this.props.camera.tags) {
            portraitTagIndex = this.props.camera.tags.findIndex((tag) => {
                return tag.id === 13
            })
        }



        return (
            <Grid.Column key={`${this.props.key}-${this.props.cameraIndex}`}>
                <Card
                    key={`camera_${this.props.camera.id}`}
                    className={`card-${statusColor}`}
                    data-testid="card--camera"
                >
                    <Photo
                        src={this.props.camera.thumbnail_url}
                        label={this.getLabel()}
                        cameraId={this.props.camera.id}
                        authAsCamera={this.props.authAsCamera}
                        infoPane={info}
                        portraitTagIndex={portraitTagIndex}
                        potVersion={this.props.camera.pot_version}
                    />
                    <Card.Content style={{padding: '0.4em'}}>
                        <Card.Header>
                            {header}
                        </Card.Header>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '0 0 .5em',
                            flexDirection: 'row-reverse'
                        }}>
                            <Card.Description style={{
                                fontSize: '1.1em'
                            }}>
                                {latestImage}
                            </Card.Description>
                            {customName}
                        </div>
                        <Card.Meta style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span className='date'>
                                {location}

                                {pcid}
                                {lastCheckInTime}
                            </span>
                            {subscriptionStatus}
                        </Card.Meta>

                    </Card.Content>
                    <Card.Content extra>
                        {cameraControlLink}
                        {authAsCameraLink}
                        {scheduleLink}
                        {monitoringLink}
                        {grayLogsLink}
                        {fileListLink}

                        {cameraUploadLink}
                        {cameraInfoLink}
                        {updateCameraLink}
                        {getSetupModeInfo}

                        {cameraTags}
                        {suppressedWarningInfo}
                    </Card.Content>
                </Card>
            </Grid.Column>
        )
    }
}

Camera.propTypes = {
    camera: PropTypes.shape({
        id: PropTypes.number.isRequired,
        pot_id: PropTypes.string.isRequired,
        clients_name: PropTypes.string,
        projects_name: PropTypes.string,
        projects_location: PropTypes.string
    }).isRequired,
    onOpenModal: PropTypes.func.isRequired,
    authAsCamera: PropTypes.func.isRequired,
    info: PropTypes.object,
    cameraIndex: PropTypes.number
}

export default Camera
